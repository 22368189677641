import Vue from "vue";
import Router from "vue-router";
// import config from "./config";
// const host = location.host;
// const hostConfig = config.site[host] || config.site["default"];
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("@/views/mainView.vue"),
      children: [
        {
          path: "",
          name: "Home",
          component: () => import("@/views/accountView.vue"),
          meta: {
            title: "账户",
          },
        },
        {
          path: "/home",
          name: "HomePage",
          component: () => import("@/views/homeAccount.vue"),
          meta: {
            title: "家庭账户",
          },
        },
        {
          path: "/shop",
          name: "Shop",
          component: () => import("@/views/shopView.vue"),
          meta: {
            title: "机房节点购买",
            hideTop: true,
            hideTips: true,
          },
        },
        {
          path: "/homeshop",
          name: "HomeShop",
          component: () => import("@/views/homeShop.vue"),
          meta: {
            hideTips: true,
            title: "家庭节点购买",
          },
        },
        {
          path: "/homeShopCustom",
          name: "HomeShopCustom",
          component: () => import("@/views/homeShopCustom.vue"),
          meta: {
            title: "家庭节点定制",
          },
        },
        {
          path: "/bill",
          name: "Bill",
          component: () => import("@/views/billView.vue"),
          meta: {
            title: "账单",
          },
        },
        {
          path: "/export_api",
          name: "ApiExport",
          component: () => import("@/views/apiExport.vue"),
          meta: {
            title: "API导出",
          },
        },
        {
          path: "/authentication",
          name: "Authentication",
          component: () => import("@/views/shenFen.vue"),
          meta: {
            title: "身份认证",
          },
        },
        {
          path: "/promotion",
          name: "Promotion",
          component: () => import("@/views/tuiGuang.vue"),
          meta: {
            title: "推广",
          },
        },
        {
          path: "/contact",
          name: "Contact",
          component: () => import("@/views/contactView.vue"),
          meta: {
            title: "联系我们",
          },
        },
        {
          path: "/download",
          name: "Download",
          component: () => import("@/views/downloadView.vue"),
          meta: {
            title: "下载",
          },
        },
        {
          path: "/download/sstap",
          name: "SStap",
          component: () => import("@/views/components/download/SstapComp.vue"),
          meta: {
            title: "Sstap",
          },
        },
        {
          path: "/download/proxifier",
          name: "Proxifier",
          component: () =>
            import("@/views/components/download/ProxifierComp.vue"),
          meta: {
            title: "Proxifier",
          },
        },
        {
          path: "/download/v2rayNG",
          name: "v2rayNG",
          component: () => import("@/views/components/download/v2rayNG.vue"),
          meta: {
            title: "v2rayNG",
          },
        },
        {
          path: "/download/robot",
          name: "robot",
          component: () => import("@/views/components/download/robotComp.vue"),
          meta: {
            title: "robot",
          },
        },
        {
          path: "/download/postern",
          name: "Postern",
          component: () =>
            import("@/views/components/download/PosternCompt.vue"),
          meta: {
            title: "Postern",
          },
        },
        {
          path: "/download/NB4A",
          name: "NB4A",
          component: () => import("@/views/components/download/NB4A.vue"),
          meta: {
            title: "NB4A",
          },
        },
        {
          path: "/renew",
          name: "Renew",
          component: () => import("@/views/renewlView.vue"),
          meta: {
            title: "续费/切换",
          },
        },
        {
          path: "/renewhome",
          name: "HomeRenew",
          component: () => import("@/views/homeRenewl.vue"),
          meta: {
            title: "续费/切换",
          },
        },
        {
          path: "/apisearch",
          name: "ApiSearch",
          component: () => import("@/views/apiSearch.vue"),
          meta: {
            title: "续费/切换",
          },
        },
        {
          path: "/pay",
          name: "Pay",
          component: () => import("@/views/payView.vue"),
          meta: {
            title: "支付",
          },
        },
        {
          path: "/searchapi",
          name: "SearchApi",
          component: () => import("@/views/searchView.vue"),
          meta: {
            title: "搜索",
          },
        },
      ],
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/loginView.vue"),
    },
  ],
});

export default router;
